import React from 'react';

function Schwierigkeiten() {
    return (
        <div>
            <p>Für discuss20 steht eine umfangreiche Video-Anleitung zur Verfügung, die Sie mit den Funktionen von discuss20 vertraut macht. Das Video können Sie jederzeit über den Button mit der Beschriftung „Anleitung“ unten links am Bildschirmrand aufrufen.</p>
            <p>Bei technischen Problemen wenden Sie sich bitte an: <a href="mailto:discuss20@hhu.de">discuss20@hhu.de</a></p>
        </div>
    );
}

export default Schwierigkeiten;
