import React from 'react';

function Netiquette() {
    return (
        <div>
            <p>
                Wir möchten Sie bitten, beim Schreiben von Beiträgen folgende Verhaltensregeln (Netiquette) einzuhalten. Nur so ist ein respektvoller und offener Meinungsaustausch möglich. Bitte beachten Sie, dass die Nichteinhaltung dieser Regeln zu einem Ausschluss von der Studie führen kann.
            </p>
            <ul>
                <li><b>Bleiben Sie beim Thema:</b> Beziehen Sie Ihre Beiträge möglichst auf das Diskussionsthema.</li>
                <li><b>Seien Sie respektvoll:</b> Machen Sie sich bitte bewusst, dass Sie auf der Diskussionsplattform in den Austausch mit anderen Menschen treten. Respektieren Sie die Meinungen anderer und greifen Sie niemanden persönlich an, auch wenn Sie einer Meinung nicht zustimmen.</li>
                <li><b>Bleiben Sie fair:</b> Vermeiden Sie Provokationen oder Beleidigungen.</li>
                <li><b>Beachten Sie das Urheberrecht:</b> Kennzeichnen Sie Zitate als solche.</li>
                <li><b>Nehmen Sie konstruktiv teil:</b> Posten Sie keine Beiträge doppelt und kopieren Sie keine Beiträge anderer. Wenn möglich, erläutern Sie Ihre Standpunkte.</li>
                <li><b>Wahren Sie die Persönlichkeitsrechte:</b> Teilen Sie keine persönlichen oder personenbezogenen Daten Dritter.</li>
                <li><b>Achten Sie auf Verständlichkeit:</b> Verfassen Sie Ihre Beiträge und Kommentare in deutscher Sprache und versuchen Sie, sich verständlich auszudrücken.</li>
                <li><b>Verlinken Sie nur geprüfte Inhalte:</b> Wenn Sie Verlinkungen verwenden, stellen Sie sicher, dass diese den Verhaltensregeln entsprechen.</li>
            </ul>
            <p><b>Das dulden wir nicht:</b></p>
            <ul>
                <li>Gewaltverherrlichende, menschenverachtende, rassistische, fremdenfeindliche, stigmatisierende, sexistische, religionsverachtende oder beleidigende Inhalte, Aufrufe zu Straftaten, Verleumdungen, geschäfts- oder rufschädigende Äußerungen sind nicht erlaubt.</li>
                <li>Spam und Werbung sind nicht erlaubt.</li>
                <li>Das Kopieren von Beiträgen oder Kommentaren anderer Nutzer:innen wird nicht geduldet und zählt nicht als Verfassen eines eigenen Beitrags.</li>
                <li>Veröffentlichen Sie den gleichen Kommentar nicht mehrmals.</li>
            </ul>
        </div>
    );
}

export default Netiquette;
