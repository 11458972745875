import React from 'react';

function ErgebnisseBeteiligung() {
    return (
        <div>
            <p>Bitte beachten Sie, dass Sie für den Erhalt der Vergütung für Ihre Studienteilnahme die folgenden Bedingungen erfüllen müssen:</p>
            <ul>
                <li>sorgfältige Teilnahme an allen drei Befragungen</li>
                <li>regelmäßiger Besuch der Plattform</li>
                <li>aktive Teilnahme an der Diskussion</li>
                <li>Teilnahme an der finalen Abstimmung</li>
            </ul>
            <p>Um die Dokumentation Ihrer erfolgreichen Teilnahme zu gewährleisten, achten Sie bitte darauf, dass Sie Diskussion, Abstimmung und Fragebögen ausschließlich durch die Links aufrufen, die Ihnen per E-Mail durch bilendi mitgeteilt werden.</p>
            <p>Nach Abschluss der letzten Befragung prüfen wir zeitnah, ob Sie alle Teilnahmebedingungen erfüllt haben. Wir bitten diesbezüglich um ein wenig Geduld.</p>
            <p>Die Vergütung erfolgt durch bilendi. Daher bitten wir, Fragen zur Auszahlung der Vergütung direkt an bilendi zu richten.</p>
        </div>
    );
}

export default ErgebnisseBeteiligung;
