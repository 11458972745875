import React from 'react';

function Regeln() {
    return (
        <div>
            <p>Als Projektverantwortliche nehmen wir <b>nicht aktiv</b> an den Diskussionen teil. Wir <b>prüfen</b> aber sehr wohl alle Diskussionsbeiträge auf Einhaltung der Verhaltensregeln (Netiquette). Bei Verstößen können entsprechende Beiträge <b>gelöscht</b> werden. Wenn Sie Beiträge anderer Nutzer:innen lesen, die gegen die genannten Regeln verstoßen, können Sie diese durch einen Klick auf den Button „Melden“ melden. In diesem Falle wird die zuständige Moderation informiert und der betreffende Beitrag überprüft. Bei schweren oder wiederholten Verstößen gegen die Diskussionsregeln oder der missbräuchlichen Nutzung anderer Funktionen der Diskussionsplattform können einzelne <b>Nutzer:innen gesperrt</b> werden. Verstöße gegen die Diskussionsregeln können außerdem dazu führen, dass die Vergütungsbedingungen der Studie nicht erfüllt werden und Sie abschließend <b>keine Vergütung</b> ausgezahlt bekommen.</p>
        </div>
    );
}
export default Regeln;
